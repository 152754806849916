<template>
	<Toolbar @clickRight="selectSearch">
		<!-- <p>{{ $route.query.menuType == 'MYJETMALL' ? 'My Jetmall' : 'Lifestyle' }}</p> -->
		<p>My Jetmall</p>
		<template v-slot:end>
			<Icon class="h-5 w-5" icon="magnifyingGlass" />
		</template>
	</Toolbar>

	<swiper class="w-full" autoplay :slides-per-view="1" :space-between="50" :parallax="true" :pagination="{ clickable: true }">
		<swiper-slide class="flex w-full flex-col items-center justify-center" v-for="banner in banners" :key="banner">
			<img :src="banner.img" alt="" />
		</swiper-slide>
	</swiper>
	<div class="mb-20 p-5">
		<div class="w-full py-5">
			<div class="mb-4 flex justify-between">
				<p class="text-xl font-bold">{{ $t('categories.categories') }}</p>
				<div class="inline-flex items-center rounded-md bg-primary px-4 text-sm text-white" @click="$router.push(`/categories`)">{{ $t('home.allItems') }}</div>
			</div>
			<div class="-ml-5 grid h-[16em] w-screen grid-flow-col grid-rows-2 gap-5 overflow-x-scroll pl-5 disable-scrollbars">
				<div v-for="category in categories" :key="category.id" class="inline-flex h-20 w-20 flex-wrap items-center justify-center gap-2 whitespace-nowrap rounded-2xl bg-gray-100" @click="setCurrentCategory(category)">
					<!-- {{ getCategoryById(categoryId)?.name[currentLang] }} -->
					<img class="mx-auto rounded-t-md object-cover p-4" :src="category.imageUrls[0]" alt="" loading="lazy" />
					<p class="text-xs">{{ category.name[currentLang] }}</p>
				</div>
			</div>
		</div>

		<div v-for="category in categories" :key="category.id" class="w-full py-5">
			<div class="mb-4 flex justify-between">
				<p class="text-xl font-bold">{{ category.name[currentLang] }}</p>
				<div class="inline-flex items-center rounded-md bg-primary px-4 text-sm text-white" @click="setCurrentCategory(category)">{{ $t('home.allItems') }}</div>
			</div>

			<div class="-mr-5 flex overflow-x-scroll rounded-l-2xl bg-gray-100 disable-scrollbars">
				<div v-for="product in category.products" :key="product.id" class="relative my-2 ml-2.5 min-h-max min-w-max rounded-md bg-white" @click="setProduct(product)">
					<div :id="product.id" class="absolute -top-52 w-full bg-red-600">text</div>
					<div v-if="product.isSoldOut" class="absolute z-30 inline-flex h-full w-full items-center justify-center rounded-md bg-secondary bg-opacity-60 text-3xl text-white">售罄</div>

					<!-- My favorite  -->
					<div v-if="inWishlist(product.id) >= 0" class="absolute top-1 right-1 h-7 w-7 text-red-500">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
							<path fill-rule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" clip-rule="evenodd" />
						</svg>
					</div>

					<!-- 商品資料 -->
					<div class="flex h-full w-28 flex-col justify-between space-y-1 rounded-md bg-white">
						<div class="space-y-2">
							<img class="mx-auto h-28 w-full rounded-t-md object-cover" :src="product.imageUrls[0]" alt="" loading="lazy" />
							<div>
								<!-- <p class="px-2 text-xs font-light text-gray-400">{{ product.sku }}</p> -->
								<p class="px-2 text-xs font-bold line-clamp-2">
									{{ product.name[currentLang] }}
								</p>
							</div>
						</div>

						<div class="relative p-2 text-xs font-bold">
							<div class="relative text-base">
								<!-- 切換價錢 -->
								<p v-if="product.variants[0]?.isCustomPrice" class="text-primary">{{ $t('categories.quoteSeparately') }}</p>
								<p v-else-if="product.variants[0].originalPrice[saleMethod] > product.variants[0].sellingPrice[saleMethod]" class="flex flex-col text-red-500">
									<del class="text-xs font-light text-muted">HK ${{ product.variants[0].originalPrice[saleMethod] }}</del
									>HK ${{ product.variants[0].sellingPrice[saleMethod] }}
								</p>
								<p v-else class="text-primary">HK ${{ product.variants[0].sellingPrice[saleMethod] }}</p>
								<!-- 已加入購物車數量 -->
								<div v-if="inCart(product.id)" class="absolute right-0 bottom-1 inline-flex h-5 w-5 items-center justify-center rounded-full bg-primary text-sm text-white">
									{{ inCart(product.id)?.product.orderedQuantity }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { storeToRefs } from 'pinia'
import { nextTick, onMounted } from 'vue'
import { useRouter } from 'vue-router'
// import { useStore } from 'vuex'
import SwiperCore, { Parallax, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { useCustomerStore } from '@/store/customer'
import { useCatalogStore } from '@/store/catalog'
import { useCartStore } from '@/store/cart'
import { useSharedStore } from '@/store/shared'
// import 'swiper/swiper.scss'
// import 'swiper/components/pagination/pagination.scss'
SwiperCore.use([Parallax, Pagination])

export default {
	components: {
		Swiper,
		SwiperSlide,
	},

	setup() {
		// const route = useRoute()
		const router = useRouter()
		// const store = useStore()

		const { config, currentLang } = storeToRefs(useSharedStore())
		const { unbindDoc } = useSharedStore()
		const { catalog, category, product } = storeToRefs(useCatalogStore())
		const { inWishlist } = useCustomerStore()
		const { currentProduct, saleMethod } = storeToRefs(useCartStore())
		const { setCurrentProduct, setCurrentVariant, cartProductQuantity, inCart } = useCartStore()
		// const { getMenuById, getCategoryById } = useCatalogStore()
		const banners = config.value.banners
		// const banners = ref([
		// 	{
		// 		img: require('../assets/img/Testing in progress.png'),
		// 	},
		// 	{
		// 		img: require('../assets/img/myjetmall-banner8.png'),
		// 	},
		// 	{
		// 		img: require('../assets/img/myjetmall-banner9.png'),
		// 	},
		// 	{
		// 		img: require('../assets/img/myjetmall-banner10.gif'),
		// 	},
		// 	// {
		// 	// 	img: require('../assets/img/myjetmall-banner4.jpg'),
		// 	// },
		// 	// {
		// 	// 	img: require('../assets/img/myjetmall-banner5.jpg'),
		// 	// },
		// 	// {
		// 	// 	img: require('../assets/img/myjetmall-banner6.jpg'),
		// 	// },
		// 	// {
		// 	// 	img: require('../assets/img/myjetmall-banner7.jpg'),
		// 	// },
		// ])

		// const menuType = computed(() => route.query.menuType)
		// const categories = computed(() => store.getters.categories(menuType.value))
		const categories = catalog.value[0]?.categories

		onMounted(() => {
			// store.commit('setLoadingState', { loadingState: true })
			document.onreadystatechange = function () {
				if (document.readyState == 'complete') {
					// store.commit('setLoadingState', { loadingState: true })
					console.log('complete')
				}
			}
			// if (menuType.value !== 'MYJETMALL' && menuType.value !== 'LIFESTYLE') router.push('/home')
			scrollToProduct()
		})
		function setCurrentCategory(val) {
			category.value = val
			router.push(`/categories/${val.id}`)
		}

		function setProduct(val) {
			product.value = val
			if (inCart(val.id)) {
				const { product, index } = inCart(val.id)
				currentProduct.value = product
				return router.push(`/products/${val.id}?index=${index}`)
			}

			setCurrentProduct(val)
			setCurrentVariant(val.variants[0])
			// store.state.currentProduct = val.id
			router.push(`/products/${val.id}`)
		}

		function scrollToProduct() {
			// console.log(currentProduct.value.id)
			const element = document.getElementById(currentProduct.value?.id)
			console.log(element)
			if (!element) return
			nextTick(() => {
				element.scrollIntoView({ behavior: 'auto', block: 'start' })
			})
		}

		function cartQuantity(id) {
			return cartProductQuantity(id)
		}

		function selectSearch() {
			const element = document.getElementById('baseLayout')
			nextTick(() => element.scrollIntoView({ behavior: 'auto', block: 'start' }))
			currentProduct.value = null
			router.push(`/search`)
		}

		return {
			currentLang,
			// menu,
			banners,
			catalog,
			saleMethod,
			// getCategoryById,
			categories,
			inCart,
			inWishlist,
			cartQuantity,
			setCurrentCategory,
			setProduct,
			unbindDoc,
			config,
			selectSearch,
		}
	},
}
</script>
